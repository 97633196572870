<template>
	<div>
		<div v-if="!hasLogin" style="background: #126bf9">
			<div class="title">高薪职位任你选</div>
			<div class="el-code-phone row-center">
				<img src="@/assets/login/3.png" alt="" style="width: 13px; height: 18px" />
				<div class="phone-area row-center pointer" @click="triangleClick">
					+86
					<!-- <div class="triangle" :class="triangleShow == true ? 'triangleOne' : ''"></div> -->
				</div>
				<input type="number" placeholder="手机号" v-model="query.phone" class="one" />
			</div>
			<div class="el-code-phone code-phone-two row-center">
				<img src="@/assets/login/5.png" alt="" class="el-mg" />

				<input type="number" placeholder="短信验证码" v-model="query.code" style="padding-left: 1.475rem" />
				<div class="text-blue pointer row-center" @click="codeClick">
					{{ second }}
				</div>
			</div>
			<div class="el-bg-blue pointer" @click="submitClick">登录/注册</div>
			<div class="flex-center el-protocol">
				<el-checkbox v-model="checked" size="small" style="padding-top:0.625rem"></el-checkbox>
				<div class="protocol-text pointer">同意干纺织 <span @click="userCLick(0)">《用户协议》</span>
					<span @click="userCLick(1)">《隐私政策》</span>
				</div>
			</div>
		</div>
		<div class="nihao nihao-one" v-if="showCompany && hasLogin && readList.length>0">
			<div class="see-one">看过的职位</div>
			<div class="list-one pointer" v-for="item in readList" :key="item.id"
				@click="navTo('/Position-details',item.position_id)">
				<div class="top-one pointer" v-if="item.position">
					<span>{{item.position.position_name}}</span>
					<span v-if="item.position.salary_min=='面议'">{{item.position.salary_min}}</span>
					<span v-else>{{item.position.salary_min}}-{{item.position.salary_max}}</span>
				</div>
				<div class="bottom-one">
					<span>{{item.ent.ent_name}}</span>
				</div>
			</div>
		</div>
		<div class="nihao pointer" v-if="showMessage" @click="navTo('/Company-details',entId)">
			<div class="see-two">
				<div>公司基本信息</div>
                <span style="width:3.75rem; height:3.75rem;display: block;border-radius: 10px;border: 1px solid #f2f5fa;text-align: center;line-height: 3.75rem;">
                    <img style="width:100%; height:auto;" :src="entObj.logo_url" alt="" />
                </span>
				<div>{{entObj.ent_name}}</div>
				<div class="enterprise-type-1">
					<img src="@/assets/forget/8.png" alt="" />
					<span>{{entObj.other_info.nature.text}}</span>
				</div>
				<div class="enterprise-type-2">
					<img src="@/assets/forget/7.png" alt="" />
					<span>{{entObj.other_info.scale.text}}</span>
				</div>
			</div>
		</div>
		<mask-user ref="maskUser" :titleShow="false" :btnCancel="false" :colseShow="false" width="1200px">
			<div class="user-protocol">
				<div>干纺织用户协议</div>
				<p>内容列表</p>
				<div>一、注册条款的接受</div>
				<div>二、用户注册条件</div>
				<div>三、用户账号、密码及安全</div>
				<div>四、服务说明</div>
				<div>五、有限责任条款</div>
				<div>六、用户权利</div>
				<div>七、用户应承诺其平台使用行为遵守以下规定</div>
				<div>八、禁止用户利用干纺织从事下列行为</div>
				<div>九、特别规定</div>
				<div>十、隐私政策</div>
				<div>十一、关于用户在干纺织的上传或张贴的内容</div>
				<div>十二、关于面试聊天等即时通讯服务</div>
				<div>十三、信息储存和限制</div>
				<div>十四、结束服务</div>
				<div>十五、禁止商业行为</div>
				<div>十六、违约责任</div>
				<div>十七、本协议条款的变更和修改</div>
				<div>十八、不可抗力</div>
				<div>十九、通知</div>
				<div>二十、法律的适用和管辖</div>
				尊敬的用户：
				在您成为干纺织注册用户，使用干纺织提供的服务之前，请您认真阅读干纺织《用户协议》（以下简称“协议”），更好地了解我们所提供的服务以及您享有的权利和承担的义务。您一旦开始使用干纺织服务，即表示您已经确认并接受了本文件中的全部条款。
				本协议系由您（以下简称“用户”或“您”）与杭州菁联网络技术有限公司（以下简称“我们”或“我们”）
				（公司地址：浙江省杭州市萧山区北干街道金城路540号心意广场3幢1501-3室）就我们提供的干纺织软件（以下简称“本平台”或“干纺织”）所订立的相关权利义务规范。干纺织软件是一个严肃纯净的招聘服务软件，请您在注册、使用干纺织之前，认真阅读以下条款。
				<p>一、注册条款的接受</p>
				一旦您在注册页面点击或勾选“阅读并同意接受用户协议及隐私政策”相关内容后，即表示您已经阅读并且同意与我们达成协议，成为干纺织的用户，并接受本协议中的全部注册条款以及干纺织《隐私政策》
				和本平台内公布的其他专项协议或规则，包括但不限于干纺织《职位信息发布规则》《干纺织增值服务协议》（以下简称“本平台规则”）的所有条款的约束。
				<p>二、用户注册条件</p>
				1. 申请注册成为干纺织的用户应同时满足下列全部条件：在注册之日以及此后使用干纺织服务期间必须以招聘和/或求职为目的；在注册之日必须年满16周岁以上。
				2.
				为了更好地享有干纺织网络信息发布平台服务，用户应遵守干纺织注册机制的要求，向干纺织提供本人真实、准确、最新及完整的资料；如注册并认证成为招聘用户，应保证及时更新本人提供的“单位名称、职务或岗位信息、企业邮箱”等相关授权信息及材料，并确保前述授权的真实性；用户应保证其职务行为，包括但不限于发布招聘信息、与求职者沟通等均在使用本平台期间持续有效；通过认证的用户应保持其招聘账号与对应的授权单位具有唯一性。
				3. 若用户提供任何错误、不实或不完整的资料，或干纺织有理由怀疑资料为错误、不实或不完整及违反用户注册条款的，或干纺织有理由怀疑其用户资料、言行等违反
				《干纺织职位信息发布规则》的，干纺织有权修改用户的注册昵称、个人说明、发布的信息等，或暂停或终止该用户使用其账号，或暂停或终止提供干纺织提供的全部或部分服务。
				4.
				若用户故意提供虚假的身份信息、公司信息进行注册，发布虚假招聘信息或者求职信息的，视为严重违反本协议，干纺织有权暂停或终止该用户账号并停止提供服务。虚假注册、发布虚假信息给干纺织造成经济、名誉等任何损失的，干纺织将追究该用户的法律责任。
				5. 若用户在使用平台时存在经营同类业务、与本平台存在竞争关系或其他利害关系的，我们将出于平台的安全性和真实性考虑，以及产品政策、行业竞争、利益冲突等原因保留向您提供服务，或限制使用部分平台功能的权利。
				<p>三、用户账号、密码及安全</p>
				1.用户有义务保证密码及账号的安全。用户应对利用该密码及账号所进行的一切活动负全部责任，包括任何经由干纺织上传、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料，无论系公开还是私下传送，均由内容提供者承担责任。
				2. 用户的密码或账号遭到未获授权的使用，或者发生其他任何安全问题时，用户应立即通知干纺织。由于用户使用不当或者其他非因干纺织导致的账号、密码泄漏，进而导致其资料、信息泄漏的，由用户承担其不利后果。
				3.
				干纺织账号的所有权归我们所有，用户完成账号注册程序后，获得干纺织账号的使用权，且该使用权仅属于账号初始注册人。同时，用户不得赠与、借用、租用、转让或售卖干纺织账号或者以其他方式许可他人使用干纺织账号。其他人不得通过受赠、继承、承租、受让或者其他任何方式使用干纺织账号。如果我们发现或者有合理理由认为账号使用者并非账号初始注册人，为保障账号安全，我们有权立即暂停或终止向该注册账号提供服务，并有权永久禁用该账号。
				4. 用户不得将账号主动告知第三方或提供给第三方进行使用，例如提供给第三方进行代为购买干纺织服务等。如因此造成其他用户隐私泄露或经济损失以及本平台损失的，用户应当承担全部责任。
				<p>四、服务说明</p>
				1. 干纺织通过国际互联网为用户提供网络服务，包括在线及离线的相关业务。为使用网络服务，用户应自行配备进入国际互联网所必需的设备，包括计算机、数据机或其它存取装置，并自行支付登录国际互联网所需要的费用。
				2.
				干纺织在提供网络服务时，可能会对部分网络服务收取一定的费用，在此情况下，会在相关页面上做明确的提示。如用户拒绝支付该等费用，则不能使用相关的网络服务。付费业务将在本注册条款的基础上另行规定服务条款，以规范付费业务的内容和双方的权利义务，包括但不限于干纺织《增值服务协议》《发票管理规范》。用户应认真阅读，如用户购买付费业务，则视为接受前述所有条款。
				3. 无论是付费服务还是干纺织的免费服务均有有效期，有效期结束后服务将自动终止，且有效期不可中断或延期。除非本注册条款或其他相关服务条款另有规定，所有付费业务均不退费。
				4.
				基于风控策略/安全风险/产品政策等的需要，干纺织可能要求部分用户补充提供材料（包括但不限于企业资质证明、承诺书、业务协议等），具体要求会在相关页面上做明确展示。如用户拒绝提供前述材料，干纺织有权视情况暂停或终止向该用户提供部分或全部服务。
				5.
				为落实《网络招聘服务管理规定》的核验更新义务，干纺织可能会不定期对部分用户的企业地址、招聘授权等相关信息进行真实性审查以及更新核验(目前，该审核机制包括“环境认证”和“线下审核”)。审查过程中，用户应配合干纺织工作人员补充相关资料(包括但不限于营业执照、资质证书/相关业务协议、授权书、被授权人身份证信息、租赁协议/水电费记录等)，并允许干纺织工作人员对其企业
				LOGO、办公环境进行审查及拍照备案(具体要求见网页说明或“官方客服”通知)。若用户拒绝，干纺织将视情况决定是否恢复该用户使用账号，或者暂停或终止为该用户提供部分或全部的招聘、求职服务。
				6.
				对于利用干纺织进行非法活动，或其言行（无论线上或者线下的）背离干纺织严肃招聘目的的，干纺织将严肃处理，包括将其列入黑名单、将其被投诉的情形公之于众、删除用户账号等处罚措施，给干纺织造成经济或者名誉等任何损失的，干纺织将追究其法律责任。
				7.
				干纺织有权通过拨打电话、发送短信或电子邮件等方式，告知用户干纺织服务相关的广告信息、促销优惠等营销信息，以及邀请用户参与版本测试、用户体验反馈、回访等活动。除系统通知或重要信息外，用户可以通过干纺织提供的方式选择不接收上述信息。
				8.
				干纺织的虚拟道具等增值服务只在产品平台上或由干纺织直接对外出售或者赠送使用权，用户不得在任何其他平台或渠道购买干纺织的虚拟产品或服务。对于用户使用非官方提供或者售卖的纺职豆、道具等虚拟产品或服务造成的任何损失，干纺织不对其负责；因在其他平台充值或找他人代充等方式购买产品或服务导致干纺织遭受损失的，用户应当承担违约责任并赔偿损失。
				9.
				用户应通过本平台使用相关服务，未经许可，不得通过其他第三方工具或运营平台获取干纺织服务，包括但不限于通过第三方软件登录干纺织账号、发布职位、浏览职位、收发简历等。如因用户使用第三方软件导致相关信息泄漏的，干纺织不承担任何责任，且用户还应承担由此给干纺织造成的损失。
				10.
				为了提高企业招聘效率，便于企业与人才沟通，您可以通过我们的小程序登录干纺织账号并与人才进行开聊、接收简历、交换联系方式、约面试等。在您的干纺织账号与小程序账号相关联的情况下，本平台规则将自动适用于干纺织账号在小程序平台中的使用。
				<p>五、有限责任条款</p>
				1.
				我们将尽力为用户提供提供安全、及时、准确、高质量的服务，但不保证一定能满足用户的要求和期望，也不保证服务不会中断，对服务的及时性、安全性、准确性都不作保证。除非另有约定，否则用户因无法使用干纺织服务，或使用服务未达到心理预期的，干纺织不承担责任。
				2.
				对于用户通过我们提供的服务传送的内容，干纺织会尽合理努力按照国家有关规定严格审查，但无法完全控制经由软件/网站服务传送的内容，不保证内容的正确性、完整性或品质。因此用户在使用干纺织服务时，可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，干纺织均不为用户经由软件/网站服务以张贴、发送电子邮件或其它方式传送的任何内容负责。但干纺织有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用软件/网站服务的全部或部分，保存有关记录，并根据国家法律法规、相关政策在必要时向有关机关报告并配合有关机关的行动。
				3.
				对于干纺织提供的各种第三方广告信息、链接、资讯等（如有），干纺织不保证其内容的正确性、合法性或可靠性，相关责任由广告主承担；并且，对于用户经由干纺织服务与广告主进行联系或商业往来，完全属于用户和广告主之间的行为，与干纺织无关。对于前述商业往来所产生的任何损害或损失，干纺织不承担任何责任。
				4. 对于用户上传的照片、资料、证件、视频、内容及图片等，干纺织已采用相关措施并已尽合理努力进行审核，但不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的用户承担。
				5.
				用户应对干纺织上的其他用户发布的内容自行加以判断，并承担因使用内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。我们无法且不会对因前述风险而导致的任何损失或损害承担责任。
				6. 是否使用软件/网站服务下载或取得任何资料应由用户自行考虑并自负风险，因任何资料的下载而导致的用户电脑系统的任何损坏或数据丢失等后果，干纺织不承担任何责任。
				7.
				对于我们在线上或线下策划、发起、组织或是承办的任何招聘相关的活动（包括但不限于收取费用以及完全公益的活动），干纺织不对上述招聘效果向用户作出任何保证或承诺，也不担保活动期间用户自身行为的合法性、合理性。由此产生的任何对于用户个人或者他人的人身或者是名誉以及其他损害，应由行为实施主体承担责任。
				8.
				对于用户的投诉，干纺织将尽合理努力进行核实和处理，但不保证一定能满足投诉者的要求。干纺织有权决定是否向公众或向被投诉者公开投诉内容。对于投诉内容侵犯用户隐私权、名誉权等合法权益的，所有法律责任由投诉者承担，与干纺织无关。
				<p>六、用户权利 用户对于自己的个人信息享有以下权利：</p>
				用户对于自己的个人信息享有以下权利：
				1. 随时查询及请求阅览，但因极少数特殊情况（如被网站加入黑名单等）无法查询及提供阅览的除外；
				2. 随时请求补充或更正，但因极少数特殊情况（如网站或有关机关为司法程序保全证据等）无法补充或更正的除外。
				<p>七、用户应承诺其平台使用行为遵守以下规定</p>
				1. 本协议所称“平台使用”是指用户使用本平台服务所进行的任何行为，包括但不限于注册、登录、认证、查看开聊、账号管理、发布招聘信息、邀约面试以及其他通过干纺织账号在本平台所进行的一切行为。
				2.
				我们提醒用户在使用干纺织服务时，应遵守《中华人民共和国民法典》《中华人民共和国个人信息保护法》《中华人民共和国网络安全法》《中华人民共和国数据安全法》《中华人民共和国就业促进法》《中华人民共和国劳动法》《中华人民共和国劳动合同法》《中华人民共和国商标法》《中华人民共和国著作权法》《全国人民代表大会常务委员会关于维护互联网安全的决定》《中华人民共和国保守国家秘密法》《中华人民共和国电信条例》《互联网信息服务管理办法》《计算机信息网络国际联网安全保护管理办法》《中华人民共和国计算机信息系统安全保护条例》《中华人民共和国计算机信息网络国际联网管理暂行规定》《网络招聘服务管理规定》《计算机信息系统国际联网保密管理规定》《网络信息内容生态治理规定》《互联网用户账号名称管理规定》等相关中国法律法规的规定。
				3. 在任何情况下，如果我们有理由认为用户使用干纺织服务过程中的任何行为，包括但不限于用户的任何言论和其它行为违反或可能违反上述法律和法规的任何规定，我们可在任何时候不经任何事先通知终止向该用户提供服务。
				4. 用户承诺在使用干纺织期间，遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。
				5. 您理解并同意，本平台仅为用户提供招聘信息分享、传播及获取招聘、求职机会的平台，您必须为自己的注册、认证账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。
				6. 用户使用本平台服务进行招聘或求职的，还应遵守干纺织《职位信息发布规则》。
				<p>八、禁止用户利用干纺织从事下列行为</p>
				禁止用户在干纺织平台或利用干纺织提供的服务，制作、发送、复制、发布、传播违反国家相关法律法规、七条底线、九不准管理规定、本平台规则的信息、从事违反前述规定/规则的活动，主要表现为：
				1. 反对宪法所确定的基本原则的。
				2. 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。
				3. 损害国家荣誉和利益的；煽动民族仇恨、民族歧视、破坏民族团结的。
				4. 破坏国家宗教政策，宣扬邪教和封建迷信的。
				5. 散布谣言，扰乱社会秩序，破坏社会稳定的。
				6. 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。
				7. 侮辱或者诽谤他人，侵害他人合法权益的。
				8. 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或有悖道德、令人反感的内容的。
				9. 含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其他内容的。
				10. 使用干纺织服务的过程中，以任何方式危害求职者合法权益的。
				11. 冒充任何人或机构，包含但不限于冒充干纺织工作人员或以虚伪不实的方式陈述或谎称与任何人或机构有关的。
				12. 发布、传播侵犯任何人的肖像权、名誉权、隐私权、专利权、商标权、著作权、商业秘密的信息或言论的。
				13. 将病毒或其它计算机代码、档案和程序，加以上载、张贴、发送电子邮件或以其它方式传送的。
				14. 跟踪或以其它方式骚扰其他用户的。
				15. 未经合法授权而截获、篡改、收集、储存或删除他人个人信息、电子邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。
				16. 以任何方式干扰或企图干扰干纺织的任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等。
				17. 未能按照本平台的流程、规则进行注册、认证或使用本服务的，违反本服务功能限制或运营策略，或采取任何措施规避前述流程、规则、限制或策略的。
				18. 未经我们的许可使用插件、外挂或通过其他第三方工具、运营平台或任何服务接入本服务和相关系统的。
				19. 利用干纺织账号或本平台服务从事，包括但不限于欺诈、传销、刷流量、好评、违法物品营销等任何违法兼职或犯罪活动的。
				20. 仿冒、混淆他人账号昵称、头像、功能介绍或发布招聘内容等，或冒充、利用他人名义对外招聘的。
				21.
				未经我们的许可，以任何目的自行或授权、允许、协助任何第三人对平台内的任何信息内容进行非法获取，用于商业用途或其他任何目的。“非法获取”是指采用包括但不限于“蜘蛛”(spider)程序、爬虫程序、拟人程序等非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、复制、转存、获得数据和信息内容的行为。
				22. 为任何注册用户或非注册用户提供自动登录到本平台、代办或协助他人代办身份认证服务的或代售身份认证所需的相关材料或凭据等。
				23. 任何导致或可能导致我们与第三方产生纠纷、争议或诉讼的行为。
				<p>九、特别规定</p>
				1. 用户如违反本协议第八条，干纺织有权在任何时候不经任何事先通知暂停或终止向该用户提供服务。
				2. 用户有下列行为或发布/散布/传播如下相关信息的，干纺织在发现或接到投诉后，有权采取冻结账号、升级认证或以其他方式暂停向该用户提供服务，并要求用户承担相应的损害赔偿责任：
				（1）涉及广告（寻求合作）、传销或直销等内容
				（2）涉及色情、淫秽内容
				（3）涉及违法/政治敏感内容
				（4）虚假信息，包括但不限于不真实的公司信息、薪资、BOSS身份、个人简历、职位信息等
				（5）利用干纺织提供的服务索取他人隐私
				（6）涉及人身攻击或其他侵害他人权益的内容
				（7）未成年人工作信息
				（8）招聘他人从事违法活动
				（9）以培训费、服装费等名义骗取求职者财物
				（10）骚扰其他用户
				（11）不符合干纺织相关服务性质的信息，如鸡汤、段子、水贴等
				（12）利用本平台可能存在的漏洞恶意充值纺职豆、获取道具等虚拟产品或服务
				（13）在本平台以外的任何第三方平台（包括但不限于淘宝、闲鱼等）售卖纺职豆、道具等虚拟产品或服务的行为
				（14）通过第三方平台或渠道（如淘宝店铺等）购买纺职豆、道具等虚拟产品或服务
				（15）涉嫌拖欠/未按法律规定支付薪资/劳务报酬的，或涉嫌具有其他可能损害劳动者或劳务人员合法权益的。
				（涉及农民工或涉众的均属于“情节严重”）本平台有权对前述情形进行处置，相关判断方式包括但不限于因上述行为被列入相关政府部门“黑名单”、被多名用户举报投诉或被新闻媒体曝光等情形
				（16）两位用户名下认证的账号，经系统判定为关联账号的，如其中一个账号因违法违规被冻结的，其他关联账号均将被同时冻结。
				（17）其他违反法律法规或国家政策以及损害干纺织及其合法用户之合法权益的行为
				3.
				根据我国现行的法律法规等相关规定，如用户实施前述第（4）项“发布虚假信息”的，包括但不限于用户发布的职位信息与其实际招聘的职位不符的，如用户实际招聘的职位为“保险销售、信用卡销售、理财产品销售、地产中介或销售或劳务派遣”，与其发布的职位信息在内容、类型或其他方面并非一致或对应的甚至不存在，我们随时有权拒绝向该用户提供服务，并可采取其他处理措施，包括但不限于“永久性封禁账号”、“永久性将其设备号、手机号等相关信息冻结”或“永久性加入‘黑名单’”等。
				<p>十、隐私政策</p>
				干纺织依法保护用户个人信息和隐私信息。有关隐私政策的内容，详见干纺织《隐私政策》
				<p>十一、关于用户在干纺织的上传或张贴的内容</p>
				1.
				用户在干纺织上传或张贴的内容（包括但不限于照片、文字、面试经历及心得评价等），视为用户授予我们及其关联公司免费、非独家的使用权，干纺织有权为展示、传播及推广前述张贴内容的目的，对上述内容进行复制、修改、出版等。该使用权持续至用户书面通知干纺织不得继续使用，且干纺织实际收到该等书面通知时止。
				2. 因用户上传或张贴的内容侵犯他人权利，而导致任何第三方向我们提出侵权或索赔要求的，用户应承担全部责任。
				3. 任何第三方对于用户在干纺织的公开使用区域张贴的内容进行复制、修改、编辑、传播等行为的，该行为产生的法律后果和责任均由行为人承担，与干纺织无关。
				<p>十二、关于面试聊天等即时通讯服务</p>
				1. 用户在接受干纺织提供与干纺织注册用户进行在线开聊、邀约面试等即时通讯服务时，应当遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚，并保证所传输的信息真实性等七条底线。
				2. 用户通过本平台与他人在线开聊、拨打电话以及视频面试等商务场景下产生的文字、语音及视频等形式的沟通信息，干纺织将会根据法律规定暂时存储，且仅用于投诉举报的处理、安全风控及离线暂存功能的实现。
				3. 干纺织对该信息的采集、传输及存储均会采取加密、防泄露等相关措施，基于用户求职安全考虑，通常我们会存储3年。
				4. 为保护其他用户隐私，您不得下载、传播或公开发布本条规定的其他用户通讯信息，如面试聊天记录等。如因此造成干纺织损失，或者侵害其他用户权益的，您应当承担违约责任或赔偿责任。
				<p>十三、信息储存和限制</p>
				干纺织有权制定一般措施及限制，包含但不限于软件服务将保留的电子邮件、聊天信息、所张贴内容或其他上载内容的最长期间、每个账号可收发沟通讯息的最大数量及可收发的单个消息的大小。通过服务存储或传送之任何信息、通讯资料和其他内容，如被删除或未予储存，干纺织不承担任何责任。
				<p>十四、结束服务</p>
				用户若反对任何注册条款的内容或对之后注册条款修改有异议，或对干纺织服务不满，用户有以下权利：不再使用干纺织服务；结束用户使用干纺织服务的资格；通知干纺织停止该用户的服务。结束用户服务的同时，用户使用干纺织服务的权利立即终止，干纺织不再对用户承担任何义务。
				<p>十五、禁止商业行为</p>
				1. 用户同意不对干纺织提供的服务或服务的任何部分，进行复制、拷贝、出售、转售或用于任何其他商业目的。
				2. 禁止通过职位向应聘者收费，如有不实，我们将结束用户使用干纺织服务的资格。
				<p>十六、违约责任</p>
				1. 用户使用虚假身份信息、公司信息进行注册，发布虚假招聘、求职信息，发布含有传销、色情、反动等严重违法内容，对外传播面试聊天等通讯记录等行为，视为严重违反本协议，应当承担给我们造成的经济损失和名誉损失。
				2.
				因用户通过干纺织提供的服务提供、张贴或传送内容、违反本服务条款、或侵害他人任何合法权益而导致任何第三人对干纺织提出任何索赔或请求，用户应当赔偿干纺织或其他合作伙伴的损失，包括但不限于赔偿金额、律师费和合理的调查费用等。
				3.
				用户在投诉其他用户有违法行为或违反本注册条款情形时，投诉者应承担不实投诉所产生的全部法律责任。如侵犯他人的合法权益，投诉人应独立承担全部法律责任。如给干纺织造成损失的，投诉人应对干纺织承担相应的赔偿责任。
				<p>十七、本协议条款的变更和修改</p>
				干纺织有权依法随时对本协议的任何条款进行变更和修改。一旦发生条款变动，我们将在干纺织软件内进行更新及提示，或将最新版本的《用户协议》以系统消息、弹框或邮件的形式发送给用户阅读及确认接收。用户如果不同意条款的修改，应主动停止使用干纺织或申请注销干纺织账号，如未使用的付费权益将在注销后清空。否则，如果用户继续使用用户账号，则视为用户已经接受本协议全部条款的修改。
				<p>十八、不可抗力</p>
				1.
				“不可抗力”是指干纺织不能合理控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误干纺织根据本注册条款履行其全部或部分义务。该事件包括但不限于政府行为、自然灾害、战争、黑客袭击、电脑病毒、网络故障等。不可抗力可能导致干纺织无法访问、访问速度缓慢、存储数据丢失、用户个人信息泄漏等不利后果。
				2. 遭受不可抗力事件时，干纺织可中止履行本协议项下的义务直至不可抗力的影响消除为止，并且不因此承担违约责任；但应尽最大努力克服该事件，减轻其负面影响。
				<p>十九、通知</p>
				干纺织向其用户发出的通知，将采用系统消息、弹窗、电子邮件或页面公告等形式。本《用户协议》的条款修改或其他事项变更时，干纺织可以以上述形式进行通知。
				<p>二十、法律的适用和管辖</p>
				本协议的生效、履行、解释及争议的解决均适用中华人民共和国的现行法律，所发生的争议应提交杭州仲裁委员会，其仲裁裁决是终局的。本协议因与中华人民共和国现行法律相抵触而导致部分条款无效的，不影响其他条款的效力。
			</div>
		</mask-user>
		<mask-privacy ref="maskPrivacy" :titleShow="false" :btnCancel="false" :colseShow="false" width="1200px">
			<div class="user-protocol">
				<div>隐私政策</div>
				<p>内容列表</p>
				<div>⼀、引⾔</div>
				<div>⼆、我们如何收集和使⽤您的个⼈信息</div>
				<div>三、我们如何使⽤cookie和同类技术</div>
				<div>四、我们如何共享、转让、公开披露您的个⼈信息</div>
				<div>五、我们如何保存及保护您的个⼈信息</div>
				<div>六、您的权利</div>
				<div>七、我们如何处理未成年⼈的个⼈信息</div>
				<div>⼋、我们对去世⽤⼾的个⼈信息保护</div>
				<div>九、本《隐私政策》的更新</div>
				<div>⼗、如何联系我们</div>
				<div>⼗⼀、适⽤法律</div>
				<div>附录：定义</div>
				<p>隐私政策</p>
				欢迎您使用干纺织产品和服务！干纺织产品和服务是由杭州菁联网络技术有限公司（注册地址：杭州市萧山区金城路540号心意广场3幢1501-3室）通过包括但不限于干纺织网站（www.ganfangzhi.com）、小程序或相关微信开放平台账号以及随技术发展可能出现的其他新形态等途径向您提供的产品和服务。我们非重视您的隐私保护和个人信息保护，特制定本《隐私政策》帮助您了解以下内容：
				<p>⼀、引⾔</p>
				为了切实保护干纺织用户隐私权和个人信息，优化用户体验，干纺织根据《中华人民共和国个人信息保护法》《中华人民共和国网络安全法》《常见类型移动互联网应用程序必要个人信息范围规定》等法律法规，并参考《信息安全技术个人信息安全规范》（GB/T
				35273-2020）等国家标准，制定本《隐私政策》。本《隐私政策》适用于我们向您提供的所有服务，无论您是通过计算机设备、移动终端或其他设备获得的干纺织服务。本《隐私政策》将详细说明每个功能可能收集的个人信息范围、收集目的，收集方式，以及拒绝提供个人信息可能的影响。请知悉，如拒绝提供非必要个人信息或附加功能所需信息时，您仍然能够使用干纺织基本功能。
				本《隐私政策》将向您说明干纺织平台可能需要调取的设备权限、调用的目的或其对应的业务功能、调用前是否询问以及用户关闭相应权限的方式，请知悉，您同意本《隐私政策》后，相应设备权限并不会默认开启，当涉及重要或敏感的设备权限时，我们会在您使用到相应业务功能时，另行弹窗再次征得您的同意后开启，权限开启后，您还可以随时通过设备设置权限关闭，您不同意开启权限，将不会影响其他非相关业务功能的正常使用。我们希望您在使用干纺织服务前仔细阅读并理解本《隐私政策》的所有内容，希望您可以根据自己的理解做出合适的选择。此外，当我们将您的个人信息用于本《隐私政策》未涵盖的用途时，我们会事先征求您的同意。
				<p>⼆、我们如何收集和使⽤您的个⼈信息</p>
				<h2>(⼀)基本功能及相关必要个⼈信息</h2>
				在您使用干纺织提供的以下服务或功能过程中，我们将基于以下基本功能收集您的相关必要个人信息。
				1.账号注册
				您首先需要注册一个干纺织账号成为干纺织的注册用户。当您注册时，您需要向我们提供您本人的手机号码，我们将通过发送短信验证码的方式来验证您的身份是否有效。如果您不提供该信息，不影响您通过www.ganfangzhi.com浏览或搜索相关招聘岗位。注：您的手机号码在您没有主动或同意交换的情况下，不会展示给其他用户。
				2.完善在线简历
				对于求职者，在您注册成功后，首先您需要完善在线简历（公开），设置您的头像，填写您的姓名、性别、职场人身份、生日、工作经历（工作起始时间、公司名称、担任职务、工作内容描述、项目经历）、教育经历（毕业学校、专业、学历、在校时间段、在校经历描述）、资格证书、社交主页、志愿者服务经历、工作期望（期望地点、期望薪资、期望岗位）。我们将根据您的以上信息，为您匹配可能合适您的岗位，将您推荐给可能合适的招聘者。您的个人信息（包括手机号码、电子邮箱、微信号）在您没有主动或同意交换的情况下，不会展示给其他用户。
				您可以在【隐私设置】中，设置在线简历信息隐藏，隐藏后招聘者无法再浏览您的在线简历内容。请注意：若您主动查看某一职位并与该职位的HR开聊，您隐藏的在线简历将会对其可见。
				<p>(⼆)附加功能及相关⾮必要个⼈信息</p>
				1.招聘者身份认证
				对于招聘者，您使用招聘服务之前，需通过实名认证。您需要向我们提供您本人的身份信息用于身份核验，包括真实的姓名、身份证号、性别、年龄以及面部识别信息（实现核验功能后即删除）；提供营业执照以验证招聘单位的合法性并证明您可以代表该单位进行招聘，保证招聘的真实性，维护求职者合法权益。认证通过后，您发布的职位方可正常对外显示。
				2.投递简历
				对于求职者，在进行简历投递前，您需要先完善在线简历，可以根据自身意愿自主设置是否在该简历中隐藏您的真实姓名及手机号码。
				您可以在【隐私设置】中，通过【真实姓名保护】【手机号码保护】进行开启/关闭相应的隐私保护功能。
				4.个性化推荐服务
				（1）您可以通过干纺织软件内的推荐页，浏览到可能会满足您的需求或期望的招聘信息。我们会基于您提交的求职意向（如您是求职者，您填写的在线简历，例如求职期望、工作经历等，如您是招聘者，您填写发布的职位信息），为您推荐、展示您可能感兴趣的或与您较为匹配的职位、求职者。
				温馨提示：干纺织无法保证推荐算法的绝对精准，也无法确保您百分百满意干纺织的推荐服务，无论有偿还是免费。如果您对算法推荐的招聘信息/与您开聊的HR的匹配度有任何不满的，可通过人工客服向我们反馈您的意见。
				（2）我们还可能会根据您提供的联系方式，通过短信、电话或您同意的其他方式联系您，向您推荐您可能感兴趣的岗位或候选人及我们提供的产品或服务。
				5.在线支付
				在您选择在线支付购买我们的纺职豆、虚拟道具、人才推荐等增值服务时，您可以选择第三方支付机构所提供的支付服务。支付功能中，我们并不收集您的个人信息，但我们需要将您的购买明细、订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。
				6.在线互动
				当您使用干纺织与求职者/招聘者进行线上沟通时，可根据自身意向，与对方交换联系方式或发送附件简历。已填写企业邮箱的招聘者，还可通过企业邮箱接收求职者主动投递的附件简历。
				7.约面试
				当您在线上与对方达成面试意向，并通过干纺织提供的约面试功能成功发出面试邀请后，如对方接受了您的线下面试邀请，您的手机号码需要向对方进行展示，避免对方在前往面试地点时无法与您取得联系。
				8.求职安全保障
				为了增强干纺织平台的求职招聘过程中的安全性，尽可能保障您或其他用户在招聘求职过程中的人身、财产安全免遭侵害，我们会收集您的以下个人信息：
				(1)
				我们仅会基于处理您的投诉举报、申诉、出于提升平台安全性的考虑，使用您在商务场景下产生的通讯信息，包括您的手机号码、微信、沟通记录（您知悉并同意，您与干纺织人工客服的通话可能会被录音），以及您的身份信息，如账号ID、手机号码、身份证号，以处理投诉举报事项和纠纷。如您为招聘者，我们可能还将使用您的面部识别信息（实现核验功能后即删除）对您进行身份核验、安全检测等。
				(2)在我们检测到您的账号和行为存在异常或违规，以及您被其他用户举报时，我们可能会要求您提供您的姓名和身份证号，以验证当前用户是否为您本人。如您拒绝提供上述信息进行身份核验，您将无法正常使用我们的产品及/或服务。
				(3)在您授权允许我们打开您的位置权限后，为发现、定位和排查行为异常，我们需要获取或更新您的设备标识信息与位置信息、操作行为。为了求职者的人身、财产安全，我们将在招聘者身份认证、发布/修改职位、通过PC端登录、在线互动等场景中，收集招聘者的位置信息以核验人企关系，防止作弊风险，保障招聘求职安全。
				(4)为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的设备信息（包括设备标识符（IMEI/MEID/ESN、androidID、IDFA、OPENUDID、GUID、OAID、IMSI、ICCID、硬件序列号）、应用信息（正在运行的应用程序列表信息、应用崩溃情况、性能数据、应用来源）、IP地址、MAC地址、及设备型号、操作系统版本、运营商信息等设备属性和状态信息）和设备所在位置相关信息（包括已授权的GPS位置、WiFi信息（SSID、BSSID及网络质量和状态数据））。
				(5)为了预防恶意程序、保障运营质量及效率，我们会收集您的服务日志信息（包括您的操作使用信息，如浏览记录、点击记录、搜索查询记录、收藏记录、发布信息、分享信息，以及操作日期和时间）。
				(6)为了验证您的身份信息，防止他人未经授权访问您的账号，我们会在您授权同意后，访问您的设备信息、微信OpenID。如您为招聘者，我们可能还将核验您的面部识别信息（实现核验功能后即删除）；在触发邮箱安全验证的场景下，我们会向您的邮箱发送验证码，如您未填写企业邮箱地址，则需要您先完成绑定。若您不同意进行上述相应的身份验证，我们可能无法利用身份信息验证的方式保障您的账号安全。
				9.其他
				为了便于您分享内容、复制信息、短信验证码以及打开已复制的链接等情形，我们需要访问您的剪切板，读取其中包含的链接、内容，从而保障您的分享、复制、打开等使用需求的便捷实现。
				<p>（三）需要您授权的其他情形</p>
				1.我们可能会将来自某项服务的信息与来自其他服务所获得的信息结合起来进行综合统计分析，用于为您提供更加精准、个性的产品、服务及/或平台活动。
				2.邀请您参与有关我们产品、服务的调查或某些您可能感兴趣的平台活动等，以评估、改进我们平台的服务效果，为您呈现更佳的服务体验。如您不希望接收此类邀请信息，您可以按照我们提示的方式选择退订。
				3.经您同意或授权的或法律法规允许的其他用途。
				<p>（四）征得授权同意的例外</p>
				根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：
				（1）与我们履行法律法规规定的义务相关的；
				（2）与国家安全、国防安全直接相关的；
				（3）与公共安全、公共卫生、重大公共利益直接相关的；
				（4）与刑事侦查、起诉、审判和判决执行等直接相关的；
				（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
				（6）所收集的个人信息是您自行向社会公众公开的；
				（7）从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；
				（8）根据您的要求签订和履行合同所必需的；
				（9）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
				（10）用于维护干纺织平台产品和/或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；
				（11）法律行政法规规定的其他情形；
				如果您对我们收集和使用您的个人信息有任何疑问或需要提供进⼀步的信息，请通过本《隐私政策》公布的联系方式与我们联系。
				<p>三、我们如何使⽤cookie和同类技术</p>
				为确保网站正常运转，我们会在您的计算机或移动设备上存储名为cookie的数据文件。cookie通常包含用户身份标识符、城市名称以及一些字符。cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用cookie技术，我们能够为您提供更加周到的服务。我们不会将cookie用于本《隐私政策》所述目的之外的任何用途。您可根据自己的偏好管理或删除cookie。有关详情，请参见aboutcookies.org。您可以清除计算机上保存的所有cookie，大部分网络浏览器都设有阻止cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于cookie的干纺织提供的服务或功能。您可以通过更改您的浏览器设置限制干纺织对cookie的使用。以chrome浏览器为例，您可以在chrome浏览器右上方的下拉菜单的“浏览器设置”中，通过“设置-高级-清除浏览数据”，选择清除您的cookie。
				<p>四、我们如何共享、转让、公开披露您的个⼈信息</p>
				们不会向第三方共享、转让您的个人信息，除非经过您本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。
				<h1>(⼀)共享</h1>
				我们会以高度的勤勉义务对待您的信息。除以下情形外，未经您同意，我们不会与除干纺织的关联公司外的任何第三方公司、组织和个人分享您的信息：
				1.关联平台之间的信息共享
				您可以通过统一的干纺织账号登录我们运营的其他产品或服务。当您登录我们的服务时，干纺织可能与我们运营的中国纱线网（“关联平台”）共享您的个人信息（包括ID信息，手机号码、头像、昵称、性别、年龄、简历信息、认证信息，以及身份标识、企业信息、招聘岗位、候选人信息等），以实现干纺织账号可在关联平台登录并使用，防止欺诈与安全风险，便于您进行统一账号和信息管理，并保障干纺织用户信息在关联平台的展示一致。
				如您是企业用户，为了提高招聘效果，我们将使用和加工您的历史数据分析该企业的招聘趋势为求职者提供参考。
				<h1> (⼆)转让</h1>
				我们不会将您的个人信息转让给除干纺织关联公司外的任何公司、组织和个人，但以下情形除外：
				1.事先获得您的明确授权或同意；
				2.满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；
				3.如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
				<h1> (三)公开披露</h1>
				我们仅会在以下情形下，公开披露您的个人信息：
				1.获得您的明确同意；
				2.基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。
				<h1>(四)例外情形</h1>
				在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
				1.与我们履行法律法规规定的义务相关的；
				2.与国家安全、国防安全直接相关的；
				3.与公共安全、公共卫生、重大公共利益直接相关的；
				4.与刑事侦查、起诉、审判和判决执行等直接相关的；
				5.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
				6.您自行向社会公众公开的个人信息；
				7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
				8.根据个人信息主体要求签订和履行合同所必需的；
				9.用于维护所提供的产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；
				10.法律法规规定的其他情形。
				<h1>五、我们如何保存及保护您的个人信息</h1>
				<h1>(⼀)保存信息</h1>
				1.信息存储
				您在使用干纺织产品及服务期间，我们将持续为您保存您的个人信息。如果您注销账号或主动删除上述信息，我们会及时删除您的个人信息或进行匿名化处理，因法律规定需要留存个人信息的，我们不会再将其用于日常业务活动中。
				2.保存地域
				我们承诺，您的个人信息将只存储在位于中华人民共和国境内的服务器上，您的信息不会被我们主动传输到境外。如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的同意，向您告知用户信息出境的目的、接收方、安全保障措施、安全风险等情况，并依法开展安全评估。
				3.例外情况
				一般而言，我们仅为实现目的所必需的合理时间保留您的个人信息，超出保留时间后，我们会根据适用法律的要求及时删除您的个人信息或进行匿名化处理。下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：
				（1）为遵守法律法规等有关规定的适用；
				（2）为遵守法院判决、裁定或其他法律程序的规定；
				（3）为遵守相关政府机关或法定授权组织的要求；
				（4）为保护我们及我们的客户、用户或雇员的人身财产安全；
				（5）其他合法权益所合理必需的用途。
				4.当我们的产品或服务发生停止运营的情形时，我们将按照法律规定向用户进行通知，通知方式包括但不限于消息推送、平台公告等，并在合理期限内删除或匿名化处理您的个人信息。
				<h1>(⼆)保护措施</h1>
				1.我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对干纺织网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；
				以及我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。
				2.我们从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过网络安全等级保护的测评。
				3.我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本《隐私政策》所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求或双方另有约定）。
				4.互联网并非绝对安全的环境，使用干纺织平台服务时，我们强烈建议您不要使用非干纺织平台推荐的通信方式发送您的信息。您可以通过我们的服务与他人建立联系和相互分享内容。当您通过我们的服务进行沟通交流、分享内容时，您可以自主选择沟通、分享的对象，作为能够看到您的联络方式、交流信息等相关信息的第三方。
				5.在使用干纺织服务进行沟通时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账号或密码发生泄露，请您立即通过本《隐私政策》的“十、如何联系我们”中公布的联系方式与我们联系，以便我们根据您的申请采取相应措施。
				请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评论、沟通时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。
				<h1>(三)安全事件通知</h1>
				1.我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。
				2.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、邮件、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过上述方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
				<p>六、您的权利</p>
				按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
				<h1>(⼀)访问和修改您的个⼈信息</h1>
				1.访问修改姓名
				（1）求职者可通过我的>在线简历>姓名修改您的姓名；
				（2）已完成实名认证的招聘者，无法直接修改姓名，可选择对外展示为“某先生/女士”。
				2.访问和修改性别
				求职者可以通过我的>在线简历>性别更改性别；
				已经完成实名认证的招聘者，不能修改性别。
				3.访问和修改简历内容
				求职者可以在我的>在线简历中修改简历内容；
				求职者可以在我的>隐私设置中修改简历隐藏或展示；
				求职者可以在我的>附件简历增加新的附件简历文件或删除已有的附件简历文件。
				4.访问和修改手机号码
				求职者可以在在线简历>基本信息中更换手机号码。
				招聘者可以在个人中心>账号与绑定中设置手机号码的绑定关系。
				5.访问和修改微信号
				招聘者可以在个人中心>账号与绑定中设置微信的绑定关系。
				6.访问和修改邮箱
				求职者可以在我的>在线简历>基本信息>邮箱中添加或修改；
				招聘者可以在个人中心>账号与绑定中添加或修改。
				7.访问和修改头像
				求职者可以在我的>在线简历>头像中查看和修改；
				招聘者可以在个人中心>个人信息>头像中查看和修改。
				8.如果您无法通过上述链接访问该等个人信息，您可以通过本《隐私政策》公布的联系方式与我们联系。
				9.您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，您可以通过上述段落中列明的方式提出更正或补充申请。为保障安全，我们将在您行使更正权前对您的身份进行验证。
				<h1>(⼆)删除您的个⼈信息</h1>
				您可以通过“1.访问和修改您的个人信息”中列明的方式删除您的部分信息。
				在以下情形，您可以通过本《隐私政策》公布的联系方式与我们联系，向我们提出删除您的个人信息的请求：
				1.如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息。
				2.如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。
				3.我们没有征求您的明确同意，收集了您的个人信息。
				4.我们违反了与您的约定来使用和处理您的个人信息。
				5.您注销了干纺织账号。
				6.我们停止对您提供服务。
				<h1>(三)提前获知产品和服务停⽌运营</h1>
				干纺织愿一直陪伴您，若因特殊原因导致干纺织平台被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。
				<h1>(四)响应请求的例外</h1>
				在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：
				1.与我们履行法律法规规定的义务相关的
				2.与国家安全、国防安全直接相关的；
				3.与公共安全、公共卫生、重大公共利益直接相关的；
				4.与刑事侦查、起诉、审判和执行判决等直接相关的；
				5.证据表明您可能存在明显恶意、滥用权利及占用平台资源、辱骂客服人员等相关情形的；
				6.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
				7.您的请求超出了一般技术手段和商业成本可覆盖的范围；
				8.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
				9.涉及商业秘密的。
				<h1>七、我们如何处理未成年⼈的个⼈信息</h1>
				干纺织非常重视对未成年人信息的保护。基于我们的产品、网站和服务的性质，我们不会故意从16岁以下的人士收集或维护我们产品和服务上的个人信息，并且我们产品和服务的任何部分都不会针对16岁以下的人士。如果您未满16岁，请不要以任何方式使用或访问我们的产品和服务。当我们得知我们无意中收集了16岁以下的人士的个⼈信息时，我们会根据适用法律法规进行删除或采取其他合适的措施。
				<h1>八、本《隐私政策》的更新</h1>
				我们可能适时修订本《隐私政策》内容。如该等变更会导致您在本《隐私政策》项下权利的实质变化，我们将在变更生效前，通过系统推送、电子邮件、短信或页面公告等方式通知您。若您不同意该等变更应停止使用干纺织平台产品和服务，或通过本《隐私政策》公布的联系方式要求暂时封停您的账号。若您继续使用我们的产品或服务，即表示您同意受修订后的本《隐私政策》的约束。
				本《隐私政策》所指的实质变化包括但不限于：
				1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
				2.个人信息共享、转让或公开披露的主要对象发生变化；
				3.您参与个人信息处理方面的权利及其行使方式发生重大变化；
				4.其他可能对您的个人信息权益产生重大影响的变化时；
				5.个人信息出境情况发生变更时。
				<h1>九、如何联系我们</h1>
				如果您对本《隐私政策》及我们对您的个人信息的处理有任何疑问、意见、建议，或您发现可能存在个人信息被泄露的情形，请通过以下方式与我们联系：
				1.我们设立了个人信息保护专职部门，您可以通过其邮箱：service@ganfangzhi.com与我们联系。
				2.我们设立了个人信息保护问题专线，您可以通过客服热线向我们反映相关问题：请拨打0571-82635788
				对于属于与本政策或个人信息保护有关的问题，我们会在收到您的信息后15个工作日内对您的请求予以答复。
				<h1>十、适用法律</h1>
				本《隐私政策》与《干纺织用户服务协议》共同构成您使用干纺织服务的基本协议文件。本《隐私政策》适用中华人民共和国现行法律法规。
				<h1>附录：定义</h1>
				本《隐私政策》中使用的特定词语，具有如下含义：
				1.“我们”或“干纺织”，指杭州菁联网络科技有限公司。
				2.“您”或“用户”，指使用杭州菁联网络科技有限公司运营的平台产品或服务的注册用户以及收费服务的购买方。
				3.“干纺织平台”或“干纺织软件”，指www.ganfangzhi.com、干纺织微信小程序。
				4.“个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。
				5.“个人信息主体”，指个人信息所标识的自然人。
				6.“个人敏感信息”，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息。
				7.“去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。
				8.“匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
			</div>
		</mask-privacy>
	</div>
</template>

<script>
	import api from "../api/user";
	import {
		mapGetters
	} from "vuex";
	import maskUser from "./mask.vue";
	import maskPrivacy from "./mask.vue";
	export default {
		components: {
			maskUser,
			maskPrivacy
		},
		computed: {
			// 2. 解构状态作为计算属性
			...mapGetters(["hasLogin"]),
		},
		props: {
			showCompany: {
				type: Boolean,
				default: true,
			},
			showMessage: {
				type: Boolean,
				default: true,
			},
			entObj: {
				type: Object,
				default: () => {
					return {}
				},

			},
			readList: {
				type: Array,
				default: () => {
					return []
				},

			},
			entId: {
				type: [String, Number]
			}
		},
		data() {
			return {
				aaa: false,
				//   判断是否为账号登录/注册还是扫码登录/注册
				loginShow: false,
				tabIndex: 1,
				//   选择手机号码地区
				triangleShow: false,
				checked: false,
				showText: true,
				second: "发送验证码",
				query: {
					phone: "",
					code: "",
				},
				IMobj: {
					is_flush: 0
				}
			};
		},

		created() {},

		mounted() {},
		methods: {
			userCLick(type) {
				if (type == 0) {
					this.$refs.maskUser.show();
				} else {
					this.$refs.maskPrivacy.show();
				}
			},
			// 忘记密码、
			navTo(path, id) {
				if (id) {
					this.$router.push({
						path,
						query: {
							id
						}
					});
					return
				}
				this.$router.push({
					path
				});
			},
			triangleClick() {
				this.triangleShow = !this.triangleShow;
			},
			// 获取用户信息
			getUserInfo() {
				api.userDetail().then((res) => {
					if (res.code == 200) {
						this.$store.commit("SET_USER", res.data);
					}
				});
			},
			// 获取腾讯im
			userSig() {
				api.getUserSig(this.IMobj).then((res) => {
					if (res.code == 200) {
						this.$store.commit("SET_SIG", res.data);
						this.timLogin(res.data.userId, res.data.userSig);
					}
				});
			},
			// 登录腾讯im
			timLogin(userID, userSig) {
				let promise = this.$tim.login({
					userID: userID.toString(),
					userSig
				});
				promise
					.then((imResponse) => {
						// 登录成功
						if (imResponse.data.repeatLogin === true) {
							// 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
						}
					})
					.catch((imError) => {
						this.IMobj.is_flush = 1;
						setTimeout(() => {
							this.userSig();
						}, 500);
					});
			},
			// 登录
			submitClick() {
				if (!this.checked) {
					this.$util.msg("请阅读协议并勾选", "error");
					return;
				}
				if (!this.$util.checkStr(this.query.phone, "mobile")) {
					this.$util.msg("请输入正确的手机号码", "error");
					return;
				} else if (this.query.code == "") {
					this.$util.msg("请输入短信验证码", "error");
					return;
				}
				const loading = this.$loading({
					lock: true,
					text: "登录中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				api.smsLogin(this.query).then((res) => {
					this.getUserInfo();
					loading.close();
					if (res.code == 200) {
						this.$util.msg("登录成功");
						let vuexData = {
							access_token: res.data.token,
							userInfo: res.data,
						};
						this.$store.commit("setToken", vuexData);
						if (res.data.user_type == 1) {
							this.userSig()
							this.$router.push({
								name: "Personal-center"
							});
						} else {
							if (res.data.is_enterprise == 0) {
								this.$router.push({
									path: "/recruiter"
								});
							} else {
								api.toggleUser({
									user_type: 2
								}).then((res) => {
									if (res.code == 200) {
										this.$router.push({
											name: "home"
										});
									}
								});
							}
						}
					} else {
						loading.close();
					}
				});
			},
			// 发送短信验证码
			codeClick() {
				if (!this.$util.checkStr(this.query.phone, "mobile")) {
					this.$util.msg("请输入正确的手机号码", "error");
					return;
				}
				const loading = this.$loading({
					lock: true,
					text: "正在获取验证码",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				if (this.showText == false) {
					this.$util.msg("请不要重复点击", "warning");
					loading.close();
					return false;
				} else {
					setTimeout(() => {
						loading.close();
						this.codeCountdown();
						this.sendSms();
					}, 2000);
				}
			},
			// 获取短信验证码
			sendSms() {
				api.getSms({
					phone: this.query.phone
				}).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg, "success");
					}
				});
			},
			// 倒计时
			// 验证码倒计时
			codeCountdown() {
				this.showText = false;
				let time = 60;
				if (time > 0) {
					var a = setInterval(() => {
						time = time - 1;
						this.second = time + "s";
						if (time == 0) {
							clearInterval(a);
							this.showText = true;
							this.second = "获取";
						}
					}, 1000);
				}
			},
		},
	};
</script>
<style lang="less" scoped>
	.title {
		width: 100%;
		height: 4.9375rem;
		font-size: 18px;
		line-height: 4.9375rem;
		text-align: center;
		font-weight: 400;
		color: #ffffff;
	}

	.el-code-phone {
		width: 80%;
		height: 42px;
		border: 1px solid #fff;
		padding-left: 20px;
		background: #fff;
		margin: 0 auto;
		margin-bottom: 24px;

		.el-mg {
			width: 18px;
			height: 13px;
		}

		.one {
			padding-left: 0.9rem;
		}

		.phone-area {
			font-size: 0.875rem;
			color: #414a60;

			div {
				margin-left: 0.475rem;
			}
		}

		input {
			height: 100%;
			width: 50%;
		}

		.img-pass {
			width: 0.875rem;
			height: 1.125rem;
			margin-right: 0.125rem;
		}
	}

	.click-yz {
		width: 100%;
		height: 2.625rem;
		background: #f7f7f7;
		border: 1px solid #cccccc;
		padding: 0 6.5625rem 0 0.8125rem;
		font-size: 0.875rem;
		margin-bottom: 2.375rem;

		div {
			width: 0.8125rem;
			height: 0.8125rem;
			background: #fff;
			border-radius: 50%;
		}
	}

	.el-protocol {
		font-size: 13px;
		align-items: flex-start;
		color: #fff;

		div {
			margin-left: 0.625rem;
		}
	}

	.el-bg-blue {
		width: 246px;
		height: 42px;
		background: #ffffff;
		text-align: center;
		line-height: 42px;
		margin: auto;
	}

	.protocol-text {
		width: 12.9375rem;
		height: 2.375rem;
		font-size: 0.8125rem;
		padding-top: 0.625rem;
		padding-bottom: 100px;

		font-weight: 400;
		color: #ffffff;
		line-height: 1.25rem;
	}

	.nihao-one {
		height: 24.5rem !important;
	}

	.nihao {
		width: 19.8125rem;
		height: 19.5rem;
		background: #ffffff;
		overflow-y: auto;

		.see-one {
			font-size: 0.9375rem;

			padding: 1.0625rem 1.6875rem;
			font-weight: bold;
			color: #126bf9;
		}

		.list-one {
			width: 16.0625rem;
			height: 4.125rem;
			margin: auto;
			border-bottom: 1px solid #eee;
			border-top: 1px solid #eee;

			.top-one {
				padding: 0.4975rem 0;

				span:nth-child(1) {
					font-size: 0.9375rem;

					font-weight: 400;
					color: #414a60;
				}

				span:nth-child(2) {
					font-size: 0.9375rem;

					font-weight: 400;
					float: right;
					color: #fc6c38;
				}
			}

			.bottom-one {
				font-size: 0.8125rem;

				font-weight: 400;
				color: #8d92a1;
			}
		}

		.see-two {
			line-height: 2.3125rem;
			font-size: 0.875rem;
			font-weight: 400;
			color: #414a60;

			img {
				width: 3.75rem;
				height: 3.75rem;
			}

			.enterprise-type-1 {
				img {
					width: 1.25rem;
					height: 1.0625rem;
				}

				span {
					padding: 0 1.25rem;
				}
			}

			.enterprise-type-2 {
				img {
					width: 1.0625rem;
					height: 1.125rem;
				}

				span {
					padding: 0 1.25rem;
				}
			}
		}
	}

	.user-protocol {
		padding: 10px;
		width: 100%;
		height: 800px;
		line-height: 50px;
		overflow-y: auto;

		div {
			color: #126bf9;
			font-size: 18px;
		}

		div:first-child {
			text-align: center;
			color: #333;
			font-size: 28px;
		}

		p {
			color: #333;
			font-size: 24px;
		}
	}
</style>
